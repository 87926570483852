import Apple from 'assets/landing-page/apple.svg'
import GooglePlay from 'assets/landing-page/googleplay.svg'
import { Button } from 'common/components/Button/Button'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

interface IDownloadApp {
  text: string
  size?: 'small' | 'large'
  color?: string
}

const DownloadApp: FC<IDownloadApp> = ({ text, size = 'large', color = '#242424' }) => {
  return (
    <Div size={size} color={color}>
      <p className="download-app w-full">{text}</p>
      <div className="buttons-div mt-3 md:mt-0">
        <Link href="https://play.google.com/store/apps/details?id=com.seaplify" passHref legacyBehavior>
          <a target="_blank">
            <Button variant="white" className="redirect-button google-play">
              <GooglePlay
                style={size === 'small' ? { width: '15.5px', height: '16.3px' } : { width: '35px', height: '37.1px' }}
              />{' '}
              Google Play
            </Button>
          </a>
        </Link>
        <Link href="https://apps.apple.com/ro/app/seaplify-maritime-partner/id6463020570" passHref legacyBehavior>
          <a target="_blank">
            <Button variant="white" className="redirect-button apple-store">
              <Apple
                style={size === 'small' ? { width: '13.8px', height: '16.6px' } : { width: '31.5px', height: '37.8px' }}
              />{' '}
              App Store
            </Button>
          </a>
        </Link>
      </div>
    </Div>
  )
}

export default DownloadApp

const Div = styled.div<{ size?: 'small' | 'large'; color: string }>`
  .download-app {
    font-weight: 700;
    color: ${({ color }) => color};

    ${({ size }) =>
      ({
        small: 'font-size: 18px; margin-bottom: 0.5rem;',
        large: 'font-size: 18px; margin-bottom: 1rem;',
      }[size!])};
  }

  .buttons-div {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  .redirect-button {
    font-weight: 500;

    ${({ size }) =>
      ({
        small: 'font-size: 12px; line-height: 20px; padding: 4px 8px; width: 115px; height: 30px',
        large: 'font-size: 21px; line-height: 28px; padding: 8px 16px; width: 205px; height: 59px',
      }[size!])};

    @media screen and (min-width: 576px) {
      max-width: 214px;
    }

    @media screen and (max-width: 576px) {
      font-size: 12px;
      line-height: 16px;
      padding: 3px 3px;
      width: 167px;
      height: 50px;
    }

    @media screen and (max-width: 400px) {
      width: 140px;
      height: 40px;
    }

    @media screen and (max-width: 340px) {
      width: 120px;
      height: 30px;
    }
  }

  @media screen and (max-width: 465px) {
    .google-play {
      svg {
        width: 16.39px !important;
        height: 16.95px !important;
      }
    }

    .apple-store {
      svg {
        width: 15.21px !important;
        height: 17.12px !important;
      }
    }
  }
`
