import LogoBlue from 'assets/LogoBlue.svg'
import DownloadApp from 'common/components/DownloadApp/DownloadApp'
import { TFunction } from 'i18next'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'

interface IIndividualsFooter {
  t: TFunction<'landing-page-company', undefined>
}

const IndividualsFooter: FC<IIndividualsFooter> = ({ t }) => {
  return (
    <StyledFooter>
      <div className="hidden md:block">
        <div className="footer-container flex flex-column md:flex-row gap-5 md:gap-0 ">
          <div className="flex flex-column gap-3">
            <LogoBlue />
            <DownloadApp text={t('download_app_now')} size="small" />
          </div>
          <div className="flex gap-5 ">
            <div>
              <div className="title mb-2">Legal</div>
              <div>
                <Link href="/privacy-policy">Privacy policy</Link>
              </div>
              <div>
                <Link href="/terms-and-conditions">Terms & Conditions</Link>
              </div>
            </div>
            <div>
              <div className="title mb-2">Contact</div>
              <div>Seaplify</div>
              <div>
                <a href="tel:+40 767 911 992">+40 767 911 992</a>
              </div>
              <div>24/7 Support Guaranteed</div>
              <div>
                <a href="mailto:contact@seaplify.com">contact@seaplify.com</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container mt-4">{t('copywright')}</div>
      </div>
      <div className="block md:hidden">
        <div className="footer-container flex flex-column md:flex-row gap-5 md:gap-0 ">
          <LogoBlue />
          <div className="flex gap-4 md:gap-5 flex-column">
            <div className="flex flex-column gap-2">
              <div className="title mb-2">Legal</div>
              <div>
                <Link href="/privacy-policy">Privacy policy</Link>
              </div>
              <div>
                <Link href="/terms-and-conditions">Terms & Conditions</Link>
              </div>
            </div>
            <div className="flex flex-column gap-2">
              <div className="title mb-2">Contact</div>
              <div>Seaplify</div>
              <div>
                <a href="tel:+40 767 911 992">+40 767 911 992</a>
              </div>
              <div>24/7 Support Guaranteed</div>
              <div>
                <a href="mailto:contact@seaplify.com">contact@seaplify.com</a>
              </div>
            </div>
          </div>
          <DownloadApp text={t('download_app_now')} size="small" />
        </div>

        <div className="footer-container mt-4">{t('copywright')}</div>
      </div>
    </StyledFooter>
  )
}

export default IndividualsFooter

const StyledFooter = styled.div`
  padding: 3rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray_40};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.black};

  .footer-container {
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    padding-inline: 3rem;
  }
`
